<template>
    <div class="giga-fields">
        <div class="giga-input_field half-field">
            <label class="input-field_label">SIRET
              <span class="text-danger">*</span>
            </label>
            <div class="input-field_area">
                <input type="text" v-model="$v.agenceForm.siret.$model" :class="{ 'field-hasError': $v.agenceForm.siret.$invalid && $v.agenceForm.siret.$anyDirty}" @keyup="emitSiret">
            </div>
              <small class="form-text text-danger" v-if="$v.agenceForm.siret.$invalid && $v.agenceForm.siret.$anyDirty">
                {{$t('siret_invalid')}}
              </small>
              <small class="form-text text-danger" v-if="!$v.agenceForm.siret.$invalid && $v.agenceForm.siret.$anyDirty && agencyNotFound">
                {{$t('agency_not_found')}}
              </small>
              <small class="form-text text-danger" v-else-if="!$v.agenceForm.siret.$invalid && siretAlreadyExists">
                <strong>{{$t('siret_already_exists')}}</strong>
              </small>

        </div>
        <div class="giga-input_field half-field">
            <label class="input-field_label">{{ $t("company_name") }}
              <span class="text-danger">*</span>
            </label>
            <div class="input-field_area">
                <input type="text" v-model="$v.agenceForm.raisonSocial.$model">
            </div>
            <div v-if="$v.agenceForm.raisonSocial.$anyDirty && $v.agenceForm.raisonSocial.$invalid">
              <small class="form-text text-danger" v-if="!$v.agenceForm.raisonSocial.required">
                {{$t('required_field')}}
              </small>
            </div>

        </div>
        <div class="giga-input_field half-field">
            <label class="input-field_label">{{ $t('address') }}
              <span class="text-danger">*</span>
            </label>
            <div class="input-field_area">
                <input type="text" v-model="$v.agenceForm.address.$model">
            </div>
            <div v-if="$v.agenceForm.address.$anyDirty && $v.agenceForm.address.$invalid">
              <small class="form-text text-danger" v-if="!$v.agenceForm.address.required">
                {{$t('required_field')}}
              </small>
            </div>

        </div>
      <div class="giga-input_field half-field">
        <label class="input-field_label">{{ $t("city") }}
          <span class="text-danger" v-if="$v.agenceForm.city.$params.required">*</span>
        </label>
        <div class="input-field_area">
          <multiselect
              id="city"
              v-model="agenceForm.city"
              :options="cities"
              open-direction="bottom"
              :searchable="true"
              :multiple="false"
              :close-on-select="true"
              :allow-empty="false"
              @open="fetchCities"
              @input="validateCity">
            <template slot="singleLabel" slot-scope="{ option }">
              {{ option }}
            </template>
            <template slot="option" slot-scope="{ option }">
              {{ option }}
            </template>
            <template slot="caret">
              <div class="multiselect__select d-flex align-items-center justify-content-center">
                <div>
                  <b-icon icon="chevron-down"></b-icon>
                </div>
              </div>
            </template>
          </multiselect>
        </div>
      </div>

    </div>
</template>

<script>

export default {
  name: "AgenceInfos",
  props: ['agenceForm', '$v','cities', 'agencyNotFound','siretAlreadyExists'],
  computed:{

  },
  methods:{
    emitSiret() {
      if (this.$v.agenceForm.siret.$invalid) {
        return;
      }
      this.$emit('onSiretChange');
    },
    validateCity(value) {
      this.$emit('update:agenceForm.city', value);
      this.agenceForm.city = value;
      this.$v.agenceForm.city.$touch();
    },
      fetchCities() {
      if (this.agenceForm.postalCode) {
        this.$emit("fetchCitiesByPostalCode", this.agenceForm.postalCode);
      }
    }
  },
}

</script>
<style lang="scss">
#city {
  display: flex;
  flex-direction: column;
  order: -1;
  width: 100px;
  height: 55%;
  padding: 8px;
  border: 0px;
  border-radius: 5px;
  margin-bottom: 5px;
}
</style>
